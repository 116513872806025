import "./App.css";
// import HomePage from "./pages/HomePage";
import MyPage from "./pages/MyPage";

function App() {

  
  return (
    <>
      {/* <HomePage /> */}
      <MyPage />
    </>
  );
}

export default App;
