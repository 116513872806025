import React, { useContext, useEffect, useState } from "react";
import Header from "../components/header/header";
import LeftSidebar from "../components/left-sidebar/LeftSidebar";
import profile from "../assets/Ellipse 50.png";
import star from "../assets/star.svg";
import bookReview from "../assets/book-review.png";
import adImg from "../assets/ad-img.png";
import flag from "../assets/flag-in.png";
import logoProfile from "../assets/question-card-img.png";
import SidebarContext from "../context/SidebarContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import img from "../assets/all-card.png";
import review from "../assets/Vector (15).png";
import following from "../assets/Vector (13).png";
import blog from "../assets/Vector (14).png";
import chat from "../assets/Chat.png";
import question from "../assets/Vector (16).png";
import QuotesCard from "../components/quotes-card/QuotesCard";
import QuestionCard from "../components/question/QuestionCard";
import { baseUrl } from "../context/baseUrl";
import { capitalizeFirstLetter } from "../common/toUpperCase";
import { toast } from "react-toastify";

function MyPage() {
  const side = useContext(SidebarContext);
  const userId = localStorage.getItem("userId");
  const { LeftSideBar, openLeftSidebar } = side;
  const navigate = useNavigate();
  const pathName = useLocation();

  const [userData, setUserData] = useState([]);
  const [quotes, setQuotes] = useState(null);
  const [reader, setReader] = useState(null);
  const [loader, setLoader] = useState(false);

  function handleOnLogOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    navigate("/login");
  }



  useEffect(() => {
    if(!userId){
      toast.error("Please Login First.")
      navigate("/login");
    }
    // async function getQuotes() {
    //   setLoader(true)
    //   try {
    //     const res = await axios({
    //       method: "get",
    //       url: `${baseUrl}/content/getallcontent/likes?content_type=Quotes`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     });
    //     setQuotes(res.data.data);
    //   } catch (e) {}
    //   setLoader(false)
    // }
    // getQuotes();
    // async function getReader() {
    //   setLoader(true)
    //   try {
    //     const res = await axios({
    //       method: "get",
    //       url: `${baseUrl}/content/getallcontent/likes?content_type=ReaderClub`,
    //       headers: {
    //         "Content-Type": "application/json",
    //         authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     });
    //     setReader(res.data.data);
    //   } catch (e) {}
    //   setLoader(false)
    // }
  
    // getReader();
    async function getUser() {
      setLoader(true)
      try {
        const res = await axios({
          method: "get",
          url: `${baseUrl}/user/getauser/${userId}`,
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setUserData(res.data.data);
      } catch (e) {}
      setLoader(false)
    }
    getUser();
  }, []);
  function handleOnEdit() {
    navigate(`/edit-profile/${userId}`);
  }
  var allQuotes = null;
  if (quotes !== null) {
    allQuotes = quotes.map((element, index) => {
      // .likes.includes("userId")?liked:notliked
      return (
        <>
          <div key={index}>
            <QuotesCard
              author={element.nickName}
              quotesId={element._id}
              description={element.description}
              date={element.createdAt}
              likes={element.likes}
              likesCount={element.likesCount}
              comments={element.commentArray}
              like={element}
            />
          </div>
        </>
      );
    });
  }

  var allReader = null;
  if (reader !== null) {
    allReader = reader.map((element, index) => {
      // .likes.includes("userId")?liked:notliked
      return (
        <>
          <div key={index}>
            <QuestionCard
              // getBlog={getReader()}
              author={element.nickName}
              questionId={element._id}
              description={element.description}
              date={element.createdAt}
              likes={element.likes}
              likesCount={element.likesCount}
              comments={element.commentArray}
              like={element}
            />
          </div>
        </>
      );
    });
  }

  return (
    <>
      <Header />
      <section className="hero-section">
        <div
          className="left-overlay"
          onClick={() => {
            openLeftSidebar();
          }}
          style={LeftSideBar ? { left: 0 } : { left: "100%" }}
        ></div>
        <div
          className="left-box"
          style={LeftSideBar ? { left: 0 } : { left: "-300px" }}
        >
          <LeftSidebar />
        </div>
        <div className="center-box">
          {/* <p className="path-name">Home{pathName.pathname}</p> */}
          <br/>
          <div
            className="d-flex align-items-center mypage-headging"
            style={{
              backgroundColor: "#FAFAFA",
              padding: "15px",
              marginBottom: "20px",
            }}
          >
        <div className="d-flex align-items-center">    <img src={profile} alt="" />
           <div>
           <h6 className="d-flex align-items-center profile-name m-0">
              {userData.nickName}
            </h6>
            <div style={{paddingLeft:'15px',marginTop:"5px"}} className="d-flex align-items-center">
                          <p className="country-name" style={{marginBottom:'0px'}}>
                            {capitalizeFirstLetter(userData?.country)} <img src={flag} alt="" />
                          </p>
                          <p className="country-name ps-3" style={{marginBottom:'0px'}}>
                            <img className="pe-1" src={review} alt="" />
                            4.5
                          </p>
                          <p className="country-name ps-3" style={{marginBottom:'0px'}}>
                            <img className="pe-2" src={following} alt="" />
                            Following <span>{userData?.following?.length}</span>
                          </p>
                        </div>
           </div>
            </div>
              <i className="bi bi-pencil-square" onClick={handleOnEdit}></i>
          </div>
          <div className="all-media">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Blog
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Quotes
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Reader
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Books
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Colleges
                </button>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="all-card">
                    <div className="row">
                      {/* <div className="col-md-3 col-sm-12">
                        <img src={img} alt="" />
                      </div> */}
                      <div className="col-md-12 col-sm-12">
                        {/* <h6 className="all-card-title">{userData.nickName}</h6>
                        <div className="d-flex align-items-center">
                          <p className="country-name">
                            {capitalizeFirstLetter(userData?.country)} <img src={flag} alt="" />
                          </p>
                          <p className="country-name ps-3">
                            <img className="pe-1" src={review} alt="" />
                            4.5
                          </p>
                          <p className="country-name ps-3">
                            <img className="pe-2" src={following} alt="" />
                            Following <span>{userData?.following?.length}</span>
                          </p>
                        </div> */}
                        <div className="row">
                          <div className="col-4">
                            <div className="d-flex align-items-center pb-3">
                              <img src={blog} alt="" />
                              <p className="all-card-text m-0">
                                Blogs <span>{userData?.blogs}</span>
                              </p>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex align-items-center pb-3">
                              <img src={review} alt="" />
                              <p className="all-card-text m-0">
                                Books <span>{userData?.books}</span>
                              </p>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex align-items-center pb-3">
                              <img src={chat} alt="" />
                              <p className="all-card-text m-0">
                                Quotes <span>{userData?.colleges}</span>
                              </p>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="d-flex align-items-center pb-3">
                              <img src={question} alt="" />
                              <p className="all-card-text m-0">
                                Q&A <span>{userData?.quotes}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {allQuotes}
                </div>
                <div
                  className="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  {allReader}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right-box">
          {/* <h2 className="right-title text-center">Member Details</h2>
          <div className="follow-profile">
            <div className="d-flex justify-content-between mb-2 align-items-center">
              <div className="d-flex align-items-center">
                <img src={logoProfile} alt="" />
                <div className="ms-3">
                  <h6 className="all-card-title">Chetan Goyal</h6>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="d-flex justify-content-between align-items-center m-0">
                      Rating
                      <img src={star} alt="" />
                      <p className="m-0">4.5</p>
                    </p>
                    <div className="d-flex justify-content-between align-items-center ps-3">
                      <p className="m-0">Following</p>
                      <p className="m-0">55</p>
                    </div>
                  </div>
                  <p className="country-name m-0">
                    India <img src={flag} alt="" />{" "}
                    <button className="follow-btn ms-3">Follow</button>
                  </p>
                </div>
              </div>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Blogs</p>
              <p className="m-0">5</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Book Review</p>
              <p className="m-0">1</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Quotes</p>
              <p className="m-0">2</p>
            </div>
            <div className="follow-content d-flex justify-content-between align-items-center">
              <p className="m-0">Q&A</p>
              <p className="m-0">5</p>
            </div>
          </div>
          <h2 className="right-title mt-3 text-center">Recent book review</h2>
          <div className="top-member-card">
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
            <div className="d-flex align-items-center review-card div justify-content-between">
              <div className="d-flex align-items-center">
                <img src={bookReview} alt="" />
                <div className="ms-2">
                  <p className="m-0 review-card-text">Rich dad and poor dad</p>
                </div>
              </div>
              <p className="m-0 review-rating-text">
                5.0 <img src={star} alt="" />
              </p>
            </div>
          </div> */}
          <h2 className="right-title mt-3 text-center">Place for ad</h2>
          <img src={adImg} alt="" />
        </div>
      </section>
      {loader && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </>
  );
}

export default MyPage;
