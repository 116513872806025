import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { AlertContainer } from "../components/Alerts/ToastifyContainer";
import { toast } from "react-toastify";
import { baseUrl } from "../context/baseUrl";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "./TextEditor";
import { capitalizeParagraph, capitalizeWords } from "../common/toUpperCase";

const content = ["Blogs", "Quotes", "Colleges", "Book"];

function AddContentPage() {
  const navigate = useNavigate();
  const [showPreview, setShowPreview] = useState({});

  const initialValue = {
    contentType: "",
    category: "",
    sub_category: "",
    heading: "",
    description: "",
    author: "",
    bookImg: null,
    city: "",
    state: "",
    country: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [contentType, setContentType] = useState(content);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [contentId, setContentId] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [show, setShow] = useState(false);
  const [filtercat, setfilterCat] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);

  const handleClose = () => setShow(false);
  const [author, setAuthor] = useState("");
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const imgRef = useRef();

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let errors = {};
    if (!formData.contentType.trim())
      errors.contentType = "Content Type is required!";
    if (!formData.heading.trim()) errors.heading = "Heading is required!";
    if (!formData.category.trim()) errors.category = "Category is required!";
    if (formData.contentType !== "Colleges" && !formData.author.trim()) {
      errors.author = "Author is required!";
    }
    if (!formData.sub_category.trim())
      errors.sub_category = "Sub Category is required!";
    if (
      formData.contentType === "Colleges" &&
      (!formData.city.trim() ||
        !formData.state.trim() ||
        !formData.country.trim())
    ) {
      errors.city = "City is required!";
      errors.state = "State is required!";
      errors.country = "Country is required!";
    }
    if (
      (formData.contentType === "Colleges" ||
        formData.contentType === "Books") &&
      (!formData.bookImg ||
        formData.bookImg === null ||
        formData.bookImg === undefined)
    ) {
      errors.bookImg = "Image is required!";
    }
    return errors;
  };

  function AddAnother() {
    window.location.reload(false);
  }

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    // Here you can store rawContentState to your desired location or state
    console.log(rawContentState); // This is the raw content of the editor
  };

  const getCategoryData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/category/all`);
      console.log(res);
      setCategoryData(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoryData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Check if description length exceeds 5000 characters
    if (name === "description" && value.length > 5000) {
      // Truncate the description to 5000 characters
      const truncatedDescription = value.slice(0, 5000);
      setFormData({
        ...formData,
        [name]: truncatedDescription,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleChangeContent = (e) => {
    setType("");
    setAuthor("");
    const selected = e.target.value;
    let filterData;
    let descriptionData = "";

    if (selected == "Blogs") {
      filterData = categoryData?.filter((ele) => ele?.contentType == "blogs");
      descriptionData = `Type: Blogs
Name Field: blogname
Additional Info: Blogs are articles written for readers.`;
    } else if (selected == "Quotes") {
      filterData = categoryData?.filter((ele) => ele?.contentType == "quotes");
      descriptionData = `Type: Quotes
Name Field: QuotesName
Additional Info: Quotes are short inspirational texts.`;
    } else if (selected == "Colleges") {
      filterData = categoryData?.filter(
        (ele) => ele?.contentType == "colleges"
      );
      descriptionData = `Type: Colleges
Name Field: collagessName
Additional Info: Colleges are educational institutions.`;
      setType("Colleges");
    } else if (selected == "Books") {
      filterData = categoryData?.filter((ele) => ele?.contentType == "books");
      descriptionData = `Type: Books
Name Field: BooksName
Additional Info: Books contain a wealth of knowledge.`;
    }

    setfilterCat(filterData);

    setFormData({
      ...formData,
      contentType: selected,
      author: "",
      description: descriptionData, // Set formatted string
    });
  };

  const handleCategory = (e) => {
    console.log(e.target.value);
    // setSelectedCat(e.target.value)
    const filter2 = filtercat?.find((ele) => ele?._id == e.target.value);
    setSelectedCat(filter2);
    setFormData({ ...formData, category: e.target.value });
  };
  console.log(filtercat, selectedCat);
  const handleSubCategory = (e) => {
    setSubCategory(e.target.value);
  };
  const handleChangeAuthor = (e) => {
    let val = e.target.value;
    setAuthor(val);
    if (val != "Author") {
      setFormData({ ...formData, author: val });
    } else {
      setFormData({ ...formData, author: "" });
    }
  };

  function handleOnClick() {
    navigate("/");
  }
  // console.log(selectedCat);
  const handleImage = (e, img) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];
    if (file && allowedTypes.includes(file.type)) {
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setShowPreview((prevPreview) => ({ ...prevPreview, [img]: objectUrl })); // Use functional update here
      setFormData((prevFormData) => ({
        ...prevFormData,
        [img]: e.target?.files[0],
      })); // Use functional update here
    } else {
      toast.error("Please Upload only Jpeg.");
    }
  };

  console.log(formData);
  async function handleOnSubmit() {
    setErrors({});
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const {
      heading,
      description,
      contentType,
      category,
      sub_category,
      author,
      bookImg,
      city,
      state,
      country,
    } = formData;
    if (contentType === "" && toast.error("Content type is Required!")) return;
    if (heading === "" && toast.error("Heading is Required!")) return;
    if (category === "" && toast.error("Category is Required!")) return;
    if (
      contentType != "Colleges" &&
      author === "" &&
      toast.error("Author is Required!")
    )
      return;
    // if (description === "" && toast.error("Description is Required!")) return;
    if (sub_category === "" && toast.error("Sub Category is Required!")) return;
    if (
      contentType == "Colleges" &&
      (city == "" || state == "" || country == "") &&
      toast.error("City, State and country is Required!")
    )
      return;
    if (
      contentType == "Colleges" &&
      (bookImg == "" || bookImg == null || bookImg == undefined) &&
      toast.error("Image is Required!")
    )
      return;
    if (
      contentType == "Books" &&
      (bookImg == "" || bookImg == null || bookImg == undefined) &&
      toast.error("Image is Required!")
    )
      return;
    // let authorField;
    // if (author === "Author") {
    //   authorField = formData.author;
    // } else {
    //   authorField = author;
    // }
    // let sub_categoryField;
    // if (category === "Other") {
    //   if (
    //     formData.sub_category === "" &&
    //     toast.error("Sub Category is Required!")
    //   )
    //     return;
    //   sub_categoryField = formData.sub_category;
    // } else {
    //   if (subCategory === "" && toast.error("Sub Category is Required!"))
    //     return;
    //   sub_categoryField = subCategory;
    // }
    // const body = JSON.stringify({
    //   contentType: formData.contentType,
    //   category: category,
    //   sub_category: sub_categoryField,
    //   heading: heading,
    //   description: description,
    //   displayImage: formData.bookImg,
    //   author: authorField,
    // });
    const formpost = new FormData();
    formpost.append("contentType", contentType || "");
    formpost.append("category", category || "");
    formpost.append("sub_category", sub_category || "");
    formpost.append("heading", heading || "");
    formpost.append("description", description || "");
    formpost.append("author", author || "");
    formpost.append("city", city || "");
    formpost.append("state", state || "");
    formpost.append("country", country || "");

    if (bookImg) {
      formpost.append("bookImg", bookImg);
    }
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return toast.error("Please Login First.");
      }
      setLoader(true);
      const res = await axios({
        method: "post",
        url: `${baseUrl}/content/create`,
        data: formpost,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + token,
        },
      });
      if (res.status === 200) {
        setTimeout(() => {
          setShow(true);
        }, 1000);
      }
      setFormData(initialValue);
    } catch (e) {
      console.log(e);
      toast.error(e?.response?.data?.message);
    }
    setLoader(false);
  }

  async function getContent() {
    try {
      const res = await axios.get(`${baseUrl}/types/getalltype`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log(res);
      // setContentType(res.data.data);
    } catch (error) {
      console.error("Error fetching content types:", error);
    }
  }

  // async function getCategory(Id) {
  //   try {
  //     const res = await axios({
  //       method: "get",
  //       url: `${baseUrl}/types/getatype/${Id}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //     setCategoryData([res.data.data]);
  //   } catch (e) {}
  // }
  function closeModal() {
    navigate("/");
  }

  useEffect(() => {
    // getContent();
    // getCategory(contentId);
  }, [contentId]);

  if (contentType === null) {
    return (
      <>
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      </>
    );
  }
  const remainingCharacters = 5000 - formData?.description?.length || 0;

  return (
    <>
      <AlertContainer />
      {/* <div className="modal-content">
        <div className="text-center">
          <h5
            id="staticBackdropLabel"
            style={{
              background: "#FFCF16",
              padding: "20px 0",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            <span className="add-content-page-title">Add Content</span>
          </h5>
        </div>
        <div className="modal-form">
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <div className="d-flex flex-column">
                <label htmlFor="content_type">Content Type</label>
                <select
                  id="content_type"
                  name="contentType"
                  onChange={handleChangeContent}
                  value={formData?.contentType}
                >
                  <option value="">-- Select --</option>
                  {["Blogs", "Quotes", "Colleges", "Books"].map(
                    (element, i) => (
                      <option key={`element-${i}`} value={element}>
                        {element}
                      </option>
                    )
                  )}
                  <option value="other">Other</option>
                </select>
              </div>
              {errors.contentType && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.contentType}
                </span>
              )}
              <input
                type="text"
                style={{ display: contentType === "other" ? "block" : "none" }}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="d-flex flex-column">
                <label htmlFor="heading">Heading</label>
                <input
                  type="text"
                  value={capitalizeParagraph(formData.heading)}
                  onChange={handleChange}
                  name="heading"
                  placeholder="Enter Heading"
                />
              </div>
              {errors.heading && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.heading}
                </span>
              )}
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="d-flex flex-column">
                <label htmlFor="category">Category</label>
                <>
                  <select
                    name="category"
                    value={formData?.category}
                    onChange={handleCategory}
                  >
                    <option value="">--Select--</option>
                    {categoryData.map((element, index) => {
                      return element.category.map((category, id) => {
                        return (
                          <option
                            value={category}
                            style={{ textTransform: "uppercase" }}
                            key={id}
                          >
                            {category}
                          </option>
                        );
                      });
                    })}
                    {filtercat?.map((element, i) => (
                      <option key={`element-${i}`} value={element?._id}>
                        {capitalizeWords(element?.categoryName)}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </select>
                </>
              </div>
              {errors.category && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.category}
                </span>
              )}
            </div>

            <div
              className="col-sm-12 col-md-3"
              style={{ display: category === "Other" ? "block" : "none" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="author">Sub Category</label>
                <input
                  type="text"
                  value={formData.sub_category}
                  onChange={handleChange}
                  name="sub_category"
                  placeholder="Enter Author name"
                />
              </div>
            </div>
            <div
              className="col-sm-12 col-md-3"
              style={{ display: category === "Other" ? "none" : "block" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="sub_category">Sub Category</label>
                <select name="sub_category" onChange={handleChange}>
                  <option value="">--Select--</option>
                  {categoryData.map((element, index) => {
                    return element.subCategory.map((subCategory, id) => {
                      return (
                        <option
                          style={{ textTransform: "uppercase" }}
                          value={subCategory}
                          key={id}
                        >
                          {subCategory}
                        </option>
                      );
                    });
                  })}
                  {selectedCat?.subcategories?.map((element, i) => (
                    <option key={`element-${i}`} value={element}>
                      {capitalizeWords(element)}
                    </option>
                  ))}
                  <option value="None">None</option>
                </select>
              </div>
              {errors.sub_category && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.sub_category}
                </span>
              )}
            </div>
            <div
              className="col-sm-12 col-md-3"
              style={{ display: type == "Colleges" ? "none" : "block" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="author">Author</label>
                <select id="" name="author" onChange={handleChangeAuthor}>
                  <option value="">--Select--</option>
                  <option value="My Self">My Self</option>
                  <option value="Anonymous">Anonymous</option>
                  <option value="Author">Author</option>
                </select>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-3"
              style={{ display: author === "Author" ? "block" : "none" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="author">Author Name</label>
                <input
                  type="text"
                  value={formData.author}
                  onChange={handleChange}
                  name="author"
                  placeholder="Enter Author name"
                />
              </div>
              {errors.author && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.author}
                </span>
              )}
            </div>
            <div
              className="col-sm-12 col-md-3"
              style={{ display: type === "Colleges" ? "block" : "none" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="author">City</label>
                <input
                  type="text"
                  value={formData.city}
                  onChange={handleChange}
                  name="city"
                  placeholder="Enter City Name"
                />
              </div>
              {errors.city && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.city}
                </span>
              )}
            </div>
            <div
              className="col-sm-12 col-md-3"
              style={{ display: type === "Colleges" ? "block" : "none" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="author">State</label>
                <input
                  type="text"
                  value={formData.state}
                  onChange={handleChange}
                  name="state"
                  placeholder="Enter State Name"
                />
              </div>
              {errors.state && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.state}
                </span>
              )}
            </div>
            <div
              className="col-sm-12 col-md-3"
              style={{ display: type === "Colleges" ? "block" : "none" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="author">Country</label>
                <input
                  type="text"
                  value={formData.country}
                  onChange={handleChange}
                  name="country"
                  placeholder="Enter State Name"
                />
              </div>
              {errors.country && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.country}
                </span>
              )}
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="d-flex flex-column">
                <label htmlFor="author">Image</label>
                <div className="d-flex justify-content-between">
                  {formData?.bookImg === null ? (
                    <>
                      <input
                        id="img1"
                        hidden
                        type="file"
                        ref={imgRef}
                        onChange={(e) => handleImage(e, "bookImg")}
                      />
                      <label htmlFor="img1" className="file-plush-btn"></label>
                    </>
                  ) : (
                    <>
                      <div>
                        <img
                          className="img-fluid preview-img"
                          alt=""
                          src={showPreview.bookImg}
                        />
                      </div>
                      <div>
                        <button
                          className="remove-btn"
                          onClick={
                            () =>
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                bookImg: null,
                              })) 
                          }
                        >
                          Delete
                        </button>
                      </div>
                    </>
                  )}
                  <input
                    type="file"
                    ref={imgRef}
                    onChange={(e) => handleImage(e, "bookImg")}
                    placeholder="Enter Author name"
                  />
                </div>
              </div>
              {errors.bookImg && (
                <span className="error-message" style={{ color: "red" }}>
                  {errors.bookImg}
                </span>
              )}
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="description">Description</label>
              <TextEditor
                convertedContent={editorState}
                setConvertedContent={setEditorState}
                setFormData={setFormData}
                formData={formData}
                flag1={true}
              />
              <textarea
                id="description"
                cols="30"
                rows="5"
                value={formData.description}
                onChange={handleChange}
                name="description"
                placeholder="Enter Description"
              ></textarea>
               <div style={{ position: 'absolute', right: "20px", color: 'gray' }}>
          {remainingCharacters}/5000
              </div>
            </div>
          </div>
          <div className="text-center">
            <button className="submit-content-btn" onClick={handleOnClick}>
              Close
            </button>
            <button className="submit-content-btn" onClick={handleOnSubmit}>
              Post
            </button>
          </div>
        </div>
      </div> */}

      <div className="newaddcontact">
        <div className="newaddcontact-div">
          <div className="newaddcontact-heading-div">
            <div className="newaddcontact-heading">
              <h2>ADD CONTENT</h2>
            </div>
          </div>
          <div className="newaddcontact-ips">
            <div className="new-flex">
              <div className="cname">
                <div className="d-flex flex-column">
                  <label htmlFor="content_type">
                    Content Type{" "}
                    {errors.contentType && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.contentType}
                      </span>
                    )}
                  </label>
                  <select
                    id="content_type"
                    className="contentType-ips"
                    name="contentType"
                    onChange={handleChangeContent}
                    value={formData?.contentType}
                  >
                    <option value="">-- Select --</option>
                    {["Blogs", "Quotes", "Colleges", "Books"].map(
                      (element, i) => (
                        <option key={`element-${i}`} value={element}>
                          {element}
                        </option>
                      )
                    )}
                    {/* <option value="other">Other</option> */}
                  </select>
                </div>

                {/* <input
                type="text"
                style={{ display: contentType === "other" ? "block" : "none" }}
              /> */}
              </div>
              <div className="cname">
                <div className="d-flex flex-column">
                  <label htmlFor="heading">
                    Heading{" "}
                    {errors.heading && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.heading}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    // value={capitalizeParagraph(formData.heading)}
                    onChange={handleChange}
                    name="heading"
                    className="heading-ips"
                    placeholder="Enter Heading"
                  />
                </div>
              </div>
              <div className="cname">
                <div className="d-flex flex-column">
                  <label htmlFor="category">
                    Category{" "}
                    {errors.category && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.category}
                      </span>
                    )}
                  </label>
                  <>
                    <select
                      name="category"
                      value={formData?.category}
                      onChange={handleCategory}
                      className="contentType-ips"
                    >
                      <option value="">--Select--</option>
                      {/* {categoryData.map((element, index) => {
                      return element.category.map((category, id) => {
                        return (
                          <option
                            value={category}
                            style={{ textTransform: "uppercase" }}
                            key={id}
                          >
                            {category}
                          </option>
                        );
                      });
                    })} */}
                      {filtercat?.map((element, i) => (
                        <option key={`element-${i}`} value={element?._id}>
                          {capitalizeWords(element?.categoryName)}
                        </option>
                      ))}
                      {/* <option value="Other">Other</option> */}
                    </select>
                  </>
                </div>
              </div>

              {/* <div
              className="col-sm-12 col-md-3"
              // style={{ display: category === "Other" ? "block" : "none" }}
            >
              <div className="d-flex flex-column">
                <label htmlFor="author">Sub Category</label>
                <input
                  type="text"
                  value={formData.sub_category}
                  onChange={handleChange}
                  name="sub_category"
                  placeholder="Enter Author name"
                />
              </div>
            </div> */}
              <div
                className="cname"
                // style={{ display: category === "Other" ? "none" : "block" }}
              >
                <div className="d-flex flex-column">
                  <label htmlFor="sub_category">
                    Sub Category{" "}
                    {errors.sub_category && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.sub_category}
                      </span>
                    )}
                  </label>
                  <select
                    className="contentType-ips"
                    name="sub_category"
                    onChange={handleChange}
                  >
                    <option value="">--Select--</option>
                    {/* {categoryData.map((element, index) => {
                    return element.subCategory.map((subCategory, id) => {
                      return (
                        <option
                          style={{ textTransform: "uppercase" }}
                          value={subCategory}
                          key={id}
                        >
                          {subCategory}
                        </option>
                      );
                    });
                  })} */}
                    {selectedCat?.subcategories?.map((element, i) => (
                      <option key={`element-${i}`} value={element}>
                        {capitalizeWords(element)}
                      </option>
                    ))}
                    <option value="None">None</option>
                  </select>
                </div>
              </div>
              <div
                className="cname"
                style={{ display: type == "Colleges" ? "none" : "block" }}
              >
                <div className="d-flex flex-column">
                  <label htmlFor="author">
                    Author{" "}
                    {errors.author && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.author}
                      </span>
                    )}
                  </label>
                  <select id="" name="author" onChange={handleChangeAuthor}>
                    <option value="">--Select--</option>
                    <option value="My Self">My Self</option>
                    <option value="Anonymous">Anonymous</option>
                    <option value="Author">Author</option>
                  </select>
                </div>
              </div>
              <div
                className="cname"
                style={{ display: author === "Author" ? "block" : "none" }}
              >
                <div className="d-flex flex-column">
                  <label htmlFor="author">Author Name</label>
                  <input
                    type="text"
                    value={formData.author}
                    onChange={handleChange}
                    name="author"
                    placeholder="Enter Author name"
                    className="contentType-ips"
                  />
                </div>
              </div>
              <div
                className="cname"
                style={{ display: type === "Colleges" ? "block" : "none" }}
              >
                <div className="d-flex flex-column">
                  <label htmlFor="author">
                    City{" "}
                    {errors.city && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.city}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    value={formData.city}
                    onChange={handleChange}
                    name="city"
                    placeholder="Enter City Name"
                  />
                </div>
              </div>
              <div
                className="cname"
                style={{ display: type === "Colleges" ? "block" : "none" }}
              >
                <div className="d-flex flex-column">
                  <label htmlFor="author">
                    State{" "}
                    {errors.state && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.state}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    value={formData.state}
                    onChange={handleChange}
                    name="state"
                    placeholder="Enter State Name"
                  />
                </div>
              </div>
              <div
                className="cname"
                style={{ display: type === "Colleges" ? "block" : "none" }}
              >
                <div className="d-flex flex-column">
                  <label htmlFor="author">
                    Country{" "}
                    {errors.country && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          marginLeft: "10px",
                          fontSize: "12px",
                        }}
                      >
                        {errors.country}
                      </span>
                    )}
                  </label>
                  <input
                    type="text"
                    value={formData.country}
                    onChange={handleChange}
                    name="country"
                    placeholder="Enter State Name"
                  />
                </div>
              </div>
              <div className="cname">
                <div className="d-flex flex-column">
                  <label htmlFor="author">
                    Image{" "}
                    {errors.bookImg && (
                      <span
                        className="error-message"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}
                      >
                        {errors.bookImg}
                      </span>
                    )}
                  </label>
                  <div
                    className="d-flex justify-content-between"
                    style={{ gap: "10px" }}
                  >
                    {formData?.bookImg === null ? (
                      <>
                        <input
                          id="img1"
                          hidden
                          type="file"
                          ref={imgRef}
                          onChange={(e) => handleImage(e, "bookImg")}
                        />
                        <label
                          htmlFor="img1"
                          className="file-plush-btn"
                        ></label>
                      </>
                    ) : (
                      <>
                        <div>
                          <img
                            className="img-fluid preview-img"
                            alt=""
                            src={showPreview.bookImg}
                          />
                        </div>
                        <div>
                          <button
                            className="remove-btn"
                            onClick={
                              () =>
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  bookImg: null,
                                })) // Use functional update here
                            }
                          >
                            X
                          </button>
                        </div>
                      </>
                    )}
                    {/* <input
                    type="file"
                    ref={imgRef}
                    onChange={(e) => handleImage(e, "bookImg")}
                    placeholder="Enter Author name"
                  /> */}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column ">
                <label htmlFor="description">Description</label>
                <TextEditor
                  convertedContent={editorState}
                  setConvertedContent={setEditorState}
                  setFormData={setFormData}
                  formData={formData}
                  flag1={true}
                />
                <textarea
                  id="description"
                  cols="30"
                  rows="5"
                  value={formData.description}
                  onChange={handleChange}
                  name="description"
                  placeholder="Enter Description"
                  className="TextEditor"
                ></textarea>
                {/* <div style={{ position: 'absolute', right: "20px", color: 'gray' }}>
          {remainingCharacters}/5000
              </div> */}
              </div>
            </div>
            <br />
            <br />
            <div className="text-center2">
              <button className="submit-content-btn" onClick={handleOnClick}>
                Close
              </button>
              <button className="submit-content-btn" onClick={handleOnSubmit}>
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <div className="content-added">Content Submission Successful.</div>
        <Modal.Footer>
          <button
            className="submit-content-btn"
            data-bs-dismiss="modal"
            onClick={closeModal}
          >
            Close
          </button>
          <button className="submit-content-btn" onClick={AddAnother}>
            Add Another
          </button>
        </Modal.Footer>
      </Modal>
      {loader ? (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default AddContentPage;
